@import "colours";

.green-rated {
    background-color: $mc-green-01;
    color: #fff;
}

.yellow-rated {
    background-color: $mc-yellow-03;
    color: #fff;
}

.amber-rated {
    background-color: $mc-orange-01;
    color: #fff;
}

.orange-rated {
    background-color: $mc-red-02;
    color: #fff;
}

.red-rated {
    background-color: $mc-red-01;
    color: #fff;
}

