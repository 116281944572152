@import "colours";
@import "variables";
@import "typography";

.input-fields {
  background-color: $mc-white-01;
  width: 100%;
  border: 1px solid #ebeef2;
  font-family: $body-font;
  min-width: 195px !important;
  padding: 9px 15px;
  //margin-top: 0.25rem;

  &:disabled {
    //background-color: $mc-grey-01;
    background-color: #d6d8dc;
    cursor: not-allowed;
    //border-left: 1px solid $mc-grey-01;
    //border-top: 1px solid $mc-grey-01;
    //border-bottom: 1px solid $mc-grey-01;
  }
}

.with-border-right {
  //border-right: 1px solid $mc-blue-01;
}

.text-area-inputs {
  min-height: 300px;
}
