@import "colours";

.mc-form-heading {
  background-color: #fff;
  color:#7b8185;
  padding: 1rem 1rem 0.9rem 1rem;
  margin-top: 1rem;
  margin-bottom: 0 !important;
}

.mc-form-heading h5 {
  font-size: 1.3rem;
  font-weight: 600;
}

.mc-form-heading .col {
  padding-left: 0 !important;
}

.mc-form-row-optional,
.mc-form-row {
  padding: 15px 25px 15px 30px;
  background-color: #ebeef2;
}

.row-modifier {
  .mc-form-row-optional,
  .mc-form-row {
  background-color: #fff;
}
}

.readonly-warning {
  //background-color: $mc-yellow-01;
  //color: $mc-blue-01;
  background-color: #ffc107;
  color:#212529;
}

.row-splitter {
  border-bottom: 1px solid $mc-blue-01;
  margin: 15px;
}

.status-info {
  padding: 5px;
  min-width: 300px;
  background-color: $mc-white-01;

  &:nth-child(odd){
    background-color: $mc-grey-02;
  }
}

.complex-question {
  margin-bottom: 1rem;
  background-color: #ebeef2;
}