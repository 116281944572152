@import "colours";
@import "variables";
@import "typography";

.stats-header-item {
    display: inline-block;
}

.bar-base {
    width: 100%;
    height: 25px;
    border-radius: 12.5px;
    //background-color: $mc-grey-01;
    background-color: #fefefe;
    z-index: 1;
}

.bar-value {
    transition: all ease-in-out 1s;
    animation-delay: 0.5s;
    height: 100%;
    border-radius: 12.5px;
    z-index: 200;
}

.outer-label-text {
    color: $mc-blue-02;
    font-family: $header-font;
    text-align: center;
}

.half-donut {
    max-width: 250px;
}

.donut-needle-parent {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 120px;
    left: 130px;
    transition: all ease 1s;
    transition-delay: .5s;
}

.donut-needle {
    width: 75px;
    position: absolute;
    transform: translate(50%, 50%);
    top: -22px;
    left: -94px;
}

