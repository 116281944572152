@import "~bootstrap";
@import "~react-toastify/dist/ReactToastify.css";
@import "~store-fetch-wrappers/dist/styles/scss/index";
@import "~pulse_table/dist/styles/scss/index";
@import "~pulse-modal/dist/styles/scss/index";

@import "core/animations";
@import "core/audits";
@import "core/buttons";
@import "core/colours";
@import "core/dropdown";
@import "core/filters";
@import "core/form";
@import "core/icons";
@import "core/inputs";
@import "core/matrixRatingTable";
@import "core/modal";
@import "core/navigation";
@import "core/page";
@import "core/pagination";
@import "core/react-date-picker";
@import "core/risk-assessment-table";
@import "core/stats";
@import "core/statsItem";
@import "core/sidebar";
@import "core/table";
@import "core/toast";
@import "core/tooltip";
@import "core/typography";
@import "core/utility";
@import "core/variables";
@import "core/grid";

@import "widgets/tabs";
@import "widgets/topbar";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}
