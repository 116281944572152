@import "colours";
@import "typography";

.risk-assessment-table-wrapper {
    padding: 0;
    margin-left: 15px !important;
}

.mc-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

.mc-table-header {
    //background: $mc-blue-01;
    //border-bottom: 1px solid $mc-blue-01;
    background-color: #3d5368;
    border: 1px solid #3d5368;
}

.mc-table-header-item {
    width: 20%;
    min-width: 150px !important;
    font-family: $header-font;
    //color: $mc-white-01;
    color: #fff;
    font-size: 0.875em;
    font-weight: 500;
}

.mc-table-row {
    &:nth-child(odd) {
        //background-color: $mc-white-01;
        //background-color: #ebeef2;
    }
}

.risk-assessment-row {
    border: 1px solid #ebeef2;
}

.risk-assessment-row {
    &:nth-child(odd) {
        //background-color: white //lighten(desaturate($mc-blue-02, 30%), 60%);
        background-color: #ebeef2;
    }
}

.mc-table-row-item {
    width: 20%;
    font-family: $body-font;
    font-size: 0.9em;
}

.table-overflow-x {
    overflow-x: auto;
}

.table-overflow-y {
    overflow-y: visible !important;
}

.table-width-wrapper {
    min-width: 1000px;
}
