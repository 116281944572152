.report-button {
    margin-bottom: 1rem;
    border-radius: .5rem;

    &:nth-child(4n-3) {
        //padding: 0 15px 0 0;
    }

    &:nth-child(4n) {
        //padding: 0 0 0 15px;
    }
}

@media only screen and (max-width: 575px) {
    .report-button {
        padding: 0 !important;
    }
}
