@import "colours";
@import "typography";
@import "variables";

.modal-background {
  padding: 0.65rem;
}

.modal-background-window {
  background: $mc-grey-02;
}
.modal-text-colour {
  color: $mc-blue-01;
  font-family: $body-font;
}

.modal {
  .header-text {
    font-family: $header-font;
  }
}
@media screen and (max-width: 400px){
  .modal {
    .wrapper-xs {
      width: 300px;
      transition: all ease $animation-duration;
    }
  }
}
