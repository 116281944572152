.d-grid {
  display: grid;
  align-items: center;
  justify-content: center;
}

.mw-20 {
  max-width: 20% !important;
}
.mw-33 {
  min-width: 33% !important;
}

.min-width-350px {
  min-width: 350px !important;
}

.min-width-500px {
  min-width: 500px !important;
}

.min-width-50px {
  min-width: 50px !important;
}

.max-width-200px {
  max-width: 200px !important;
}

.max-width-250px {
  max-width: 250px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}
